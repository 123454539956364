import request from '@/utils/request'
let resquest = "/api/"

// 供应商
export function baseSupplierGetValid(data) {
  return request({
      url: `${resquest}base/supplier/getValidList`,
      method: 'post',
      data
  })
}

//成交状态
export function getValidList(data) {
  return request({
    url: `${resquest}base/dist/getValidList`,
    method: 'post',
    data
  })
}

//港口
export function portGetValidList(data) {
  return request({
    url: `${resquest}base/port/getValidList`,
    method: 'post',
    data
  })
}

//省市区
export function areasGetValidList(data) {
  return request({
    url: `${resquest}base/areas/getValidList`,
    method: 'post',
    data
  })
}

//省市区
export function streetAndAreasGetValidList(data) {
  return request({
    url: `${resquest}base/streetAndAreas/getValidList`,
    method: 'post',
    data
  })
}

//区
export function selectByCityId(data) {
  return request({
    url: `${resquest}base/areas/selectByCityId`,
    method: 'post',
    data
  })
}

//业务员
export function salesmanGetValidList(data) {
  return request({
    url: `${resquest}base/salesman/getValidList`,
    method: 'post',
    data
  })
}

//产品专员
export function productCommissionerGetValidList(data) {
  return request({
    url: `${resquest}base/productCommissioner/getValidList`,
    method: 'post',
    data
  })
}

//散货查询接口
export function bulkCargoList(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/list`,
    method: 'post',
    data
  })
}

//创建散货询价接口
export function bulkCargoSave(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/save`,
    method: 'post',
    data
  })
}

//散货询价编辑回显接口
export function bulkCargoGetById(params) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/getById`,
    method: 'get',
    params
  })
}


//散货询价复制回显数据接口
export function bulkCargoCopy(params) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/copy`,
    method: 'get',
    params
  })
}

//散货询价修改接口
export function bulkCargoEdit(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/edit`,
    method: 'post',
    data
  })
}

//散货询价删除接口
export function bulkCargoDelete(params) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/delete`,
    method: 'get',
    params
  })
}

//散货修改报价状态
export function bulkCargoUpdateDetailStatus(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/updateDetailStatus`,
    method: 'post',
    data
  })
}




//拖车查询接口
export function bulkCargoCarList(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/list`,
    method: 'post',
    data
  })
}

//创建拖车询价接口
export function bulkCargoCarSave(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/save`,
    method: 'post',
    data
  })
}

//拖车询价编辑回显接口
export function bulkCargoCarGetById(params) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/getById`,
    method: 'get',
    params
  })
}

//拖车询价复制回显数据接口
export function bulkCargoCarCopy(params) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/copy`,
    method: 'get',
    params
  })
}


//拖车询价修改接口
export function bulkCargoCarEdit(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/edit`,
    method: 'post',
    data
  })
}

//拖车询价删除接口
export function bulkCargoCarDelete(params) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/delete`,
    method: 'get',
    params
  })
}
//拖车修改报价状态
export function bulkCargoCarUpdateDetailStatus(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/updateDetailStatus`,
    method: 'post',
    data
  })
}

//地址检索接口
export function areasPlaceSuggestion(params) {
  return request({
    url: `${resquest}base/areas/placeSuggestion`,
    method: 'get',
    params
  })
}
//散货修改报价状态前查询该询价是否已有其他&lt;已成交&gt;状态的报价信息
export function bulkCargoCheckDetailStatus(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/checkDetailStatus`,
    method: 'post',
    data
  })
}

//拖车修改报价状态前查询该询价是否已有其他&lt;已成交&gt;状态的报价信息
export function bulkCargoCarCheckDetailStatus(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/checkDetailStatus`,
    method: 'post',
    data
  })
}

//产品查询修改总销售价
export function trailCarCostProductSelectEdit(data) {
  return request({
    url: `${resquest}trailCarCost/productSelectEdit`,
    method: 'post',
    data
  })
}





//手机端测试
export function appgGetPortList(data) {
  return request({
    url: `${resquest}app/getPortList `,
    method: 'post',
    data
  })
}